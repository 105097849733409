import Image8 from '../assets/CardImage8.jpeg'
import Image7 from '../assets/CardImage7.jpeg'
import Image4 from '../assets/CardImage4.jpeg'


const GallaryData = [
    {
        
        image : Image8
    },
    {
       
        image : Image7
    },
    {
        
        image : Image4
    },
    
]

export default GallaryData