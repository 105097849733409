import Enpowerment from '../assets/womenEnpowerment.png'
import Green from '../assets/seeding.png'
import Education from '../assets/presentation.png'
import Youth from '../assets/youth-day.png'


const BannerData = [
    {
        title: 'Woman Empowerment',
        image : Enpowerment
    },
    {
        title: 'Clean Environment',
        image : Green
    },
    {
        title: ' Education',
        image : Education
    },
    {
        title: 'Youth Activity',
        image : Youth
    },
    
]
export default BannerData